import React, { useRef }  from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Main from "../components/main"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
      <Layout>
          <p>somethign about business here</p>
      </Layout>
  )
}

export default IndexPage