import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = theme => ({
  msg: {
    color: theme.typography.errorText,
    margin: theme.spacing.unit * 4
    // color: theme.typography.darkText
  }
});

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function ContactDialog(props) {
  const { fullScreen } = props;
  const [formSetting, setFormSetting] = useState({
    formName: "contact",
    botField: ""
  });

  const [formData, setFormData] = useState({
    name: "name",
    email: "somethign",
    message: "else"
  });

  const handleSubmit = e => {
    e.preventDefault();
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formSetting.formName,
        name: formData.name,
        email: formData.email,
        message: formData.message,
        __bf: formSetting.botField
      })
    })
      .then(res => props.handleClose(res))
      .catch(err => props.handleClose(err));
  };

  const handleChange = e => {
    const efield = e.target.name;
    const value = e.target.value;
    setFormData(formData => ({ ...formData, [efield]: value }));
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
      >
        <form onSubmit={e => handleSubmit(e)}>
          <DialogTitle id="contact-dialog-title">
            <div style={{ fontSize: 32 }}>
              Leave me a message
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText />
            <input type="hidden" name="form-name" value={formData.formName} />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              required
              fullWidth
              variant="outlined"
              onChange={e => handleChange(e)}
            />
            <TextField
              margin="dense"
              id="email"
              name="email"
              label="Email address"
              required
              fullWidth
              variant="outlined"
              onChange={e => handleChange(e)}
            />
            <TextField
              margin="dense"
              id="message"
              name="message"
              required
              label="Message"
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              onChange={e => handleChange(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.handleClose()} color="primary">
              Cancel
            </Button>
            <Button color="primary" label="Submit" type="submit">
              Send Message
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(ContactDialog)
);
