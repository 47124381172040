import React, { useRef } from "react"

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons"

import ContactDialog from './ContactDialog';
import { Link, navigate } from "gatsby"

import Image from "./image"
import logo from "../images/logo_rnd.svg"
import cloud from "../images/cloud.png"
import ring from "../images/ring.png"
import bb from "../images/bb.svg"
import gi from "../images/gi.svg"
import bs from "../images/bs.svg"
import stars from "../images/stars.svg"
import satellite from "../images/satellite.svg"

import Button from "@material-ui/core/Button";

const Main = () => {
  const parallaxRef = useRef(null)
  const [contactDialogOpen, setContactDialogOpen] = React.useState(false);
  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
  };

  const url = (name, wrap = false) =>
    `${
      wrap ? "url(" : ""
    }https://awv3node-homepage.surge.sh/build/assets/${name}.svg${
      wrap ? ")" : ""
    }`
  return (
    <div style={{ height: "100%" }}>
      <ContactDialog
        open={contactDialogOpen}
        handleClose={handleContactDialogClose}
      />
      <Parallax ref={parallaxRef} pages={4}>
        {/* <ParallaxLayer
          offset={1}
          speed={1}
          factor={2}
          style={{ backgroundColor: "#3685B5" }} // 9ED0E6 805E73  #B796AC 2D3142
        /> */}
        {/* <ParallaxLayer
          offset={2}
          speed={1}
          factor={3}
          style={{ backgroundColor: "#9ED0E6" }} //9ED0E6 87BCDE 0471A6 3685B5
        >
                    <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setContactDialogOpen(true)}
                >
                  Get in Touch
                </Button>
        </ParallaxLayer> */}
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={4}
          style={{
            backgroundImage: url("stars", true),
            backgroundSize: "cover",
          }}
        />
        <ParallaxLayer
          offset={0.75}
          speed={-0.3}
          style={{ pointerEvents: "none" }}
        >
          
          <img
            src={satellite}
            // src={ring}
            style={{ width: "10%", marginLeft: "80%" }}
          />
          
        </ParallaxLayer>
        <ParallaxLayer offset={1.2} speed={0.8} style={{ opacity: 0.1 }}>
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "55%" }}
          />

          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "10%", marginLeft: "15%" }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={1.95} speed={0.5} style={{ opacity: 0.1 }}>
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "70%" }}
          />
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "40%" }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={1.2} speed={0.2} style={{ opacity: 0.2 }}>
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "10%", marginLeft: "10%" }}
          />
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "75%" }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={1.8} speed={-0.1} style={{ opacity: 0.4 }}>
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "60%" }}
          />
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "25%", marginLeft: "30%" }}
          />
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "10%", marginLeft: "80%" }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={2.8} speed={0.4} style={{ opacity: 0.6 }}>
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "20%", marginLeft: "5%" }}
          />
          <img
            src={cloud}
            alt="cloud"
            style={{ display: "block", width: "15%", marginLeft: "75%" }}
          />
        </ParallaxLayer>

        <ParallaxLayer offset={3.5} speed={0.5}>
          <div style={{ minWidth: "1920px" }}>
            <Image />
          </div>
        </ParallaxLayer>

       {/* <ParallaxLayer offset={3.5} speed={0.5}>
          <Button 
          primary 
          label={'get in touch'}
          onClick={()=>{setContactDialogOpen(true)}}
          /> 
        </ParallaxLayer> */}

        <ParallaxLayer
          offset={1}
          speed={-0.05}
          // onClick={() => parallaxRef.current.scrollTo(1)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents:"none",
          }}
        >
          <Link

            to="/initiatives"
            style={{ width: "25%", marginLeft: "50%", pointerEvents:'all'}}
          >
            <img src={gi} style={{ width: "100%" }} alt="gi images" />
          </Link>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={-0.05}
          // onClick={() => parallaxRef.current.scrollTo(1)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents:"none",
          }}
        >
          <Link 

            to="/blue-sky"
            style={{ width: "25%", marginRight: "50%", pointerEvents:'all' }}

          >
            <img src={bs} style={{ width: "100%" }} alt="bs images" />
          </Link>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents:"none",
          }}
          // onClick={() => parallaxRef.current.scrollTo(0)}
        >
          {/* <img src={url("clients-main")} style={{ width: "40%" }} /> */}
          <Link to="/business" style={{ width: "40%", pointerEvents:'all'}}>
            <img src={bb} style={{ width: "100%" }} />
          </Link>

        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallaxRef.current.scrollTo(1)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} style={{ width: "40%" }} alt="website logo" />
        </ParallaxLayer>

        {/* <ParallaxLayer
          offset={2}
          speed={0.1}
          onClick={() => parallaxRef.current.scrollTo(2)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        </ParallaxLayer> */}
      </Parallax>
    </div>
  )
}

export default Main
